import { Typography, ArrowUpIcon, ArrowDownIcon, TvIcon, ClipIcon, CircularProgress } from 'vlab-frontend-components'
import {
  AccordionContainer,
  AccordionHeader,
  AccordionContent,
  AccordionDetails,
} from '../../../../components/Accordion/style'
import { useState } from 'react'
import styled from 'styled-components'
import { useApi } from '../../../../hooks/useAPi'
import { useQuery } from 'react-query'
import { ResultFiles, Results } from '../../../../types/Results'
import { format } from 'date-fns'
import { LoadingContainer } from '../../../../components/LoadingContainer'

interface AccordionReportProps {
  result: Results
  setSelectedFile: (result: Partial<ResultFiles>) => void
  setSelectedsResults: (selectedsResults: any[]) => void
  selectedsResults: any[]
}

const ResultFileItem = styled.div`
  display: flex;
  padding: 10px;
  gap: 8px;
  cursor: pointer;
  &:hover {
    background-color: #dfe8f8;
    transition: background-color 0.3s;
  }
`

export default function AccordionResult({
  result,
  setSelectedFile,
  setSelectedsResults,
  selectedsResults,
}: AccordionReportProps) {
  const { getResultFiles } = useApi()
  const [open, setOpen] = useState(false)

  const { data, isLoading } = useQuery(
    ['resultFiles', result.id],
    () => getResultFiles(result.medical_report_id, result.id),
    {
      refetchOnWindowFocus: false,
    }
  )

  //file types first in array
  const resultsData = data ? data.sort((a, b) => {
    if (a.file_type === 1 && b.file_type !== 1) {
      return -1;
    }
    if (a.file_type !== 1 && b.file_type === 1) {
      return 1;
    }
    return 0;
  }) : []

  function handleSelectFile(file: { url: string; filename: string; file_type: number }) {
    const alreadySelected = selectedsResults?.find((item) => item.url === file.url)
    if (alreadySelected) {
      setSelectedsResults(selectedsResults?.filter((item) => item.url !== file.url) || [])
    } else {
      setSelectedsResults([...(selectedsResults || []), file])
    }
  }

  if (isLoading) return (
    <LoadingContainer>
      <CircularProgress style={{ width: '50px', height: '50px' }} />
    </LoadingContainer>
  )
  if (!data) return null

  return (
    <AccordionContainer
      style={{
        marginTop: '0',
        width: '100%',
      }}
      onClick={(e) => {
        setOpen(!open)
        e.stopPropagation()
      }}
    >
      <AccordionHeader>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography
            color="black"
            type="poppinsBodyS"
            style={{ fontWeight: 300, marginRight: 10 }}
          >
            {result.procedure.custom_name || result.procedure.tuss}
          </Typography>

          <div style={{ width: 16, height: 16, marginLeft: 10 }}>
            {open ? <ArrowUpIcon color="#51525F" /> : <ArrowDownIcon color="#51525F" />}
          </div>
        </div>
      </AccordionHeader>
      <AccordionContent isOpen={open} style={{ backgroundColor: '#ffff' }}>
        <AccordionDetails
          style={{
            backgroundColor: '#ffff',
            padding: 0,
            marginTop: 0,
          }}
        >
          {resultsData.map((file) => (
            <ResultFileItem
              onClick={(event) => {
                setSelectedFile(file)
                event.stopPropagation()
              }}
            >
              <input
                type="checkbox"
                checked={!!selectedsResults?.find((item) => item.url === file.url)}
                onChange={() => {
                  handleSelectFile({
                    url: file.url,
                    filename: file.filename,
                    file_type: 1,
                  })
                }}
                onClick={(e) => e.stopPropagation()}
              />
              <ClipIcon color={'#0166FF'} />
              <div>
                <Typography color="black" type="poppinsBodyS">
                  {file.filename}
                </Typography>
              </div>
            </ResultFileItem>
          ))}
          {result.exams.map((exam) => (
            <ResultFileItem
              onClick={(event) => {
                setSelectedFile({
                  url: exam.exam,
                  filename: exam.room_description,
                  file_type: 3,
                })
                event.stopPropagation()
              }}
            >
              {exam.has_mp4 && (
                <input
                  type="checkbox"
                  checked={!!selectedsResults?.find((item) => item.url === exam.exam)}
                  onClick={(e) => e.stopPropagation()}
                  onChange={() => {
                    handleSelectFile({
                      url: exam.exam,
                      filename: exam.room_description,
                      file_type: 3,
                    })
                  }}
                />
              )}

              <TvIcon color={'#0166FF'} />
              <div>
                <Typography color="black" type="poppinsBodyS">
                  {exam.organization_description} - {exam.unit_description} -{' '}
                  {format(new Date(exam.exam_date), 'dd/MM/yyyy')}
                </Typography>
              </div>
            </ResultFileItem>
          ))}
        </AccordionDetails>
      </AccordionContent>
    </AccordionContainer>
  )
}
