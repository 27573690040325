import {
  CircularProgress,
  CloseIcon,
  DoubleImageCarousel,
  Select,
  Sidebar,
  Typography,
} from 'vlab-frontend-components'
import { useEffect, useState } from 'react'
import FlexContainer from '../../components/Flex'
import { Opacity, Content, CloseIconWrapper, Wrapper, Header } from './style'
import { useQuery } from 'react-query'
import { useApi } from '../../hooks/useAPi'
import { Exam } from '../../types/exams'
import { Clappr } from '../../components/Clappr'
import ExamCard from './components/Card'
import Pagination from '../../components/Pagination'
import { LoadingContainer } from '../../components/LoadingContainer'
import AccordionExam from './components/AccordionExam'
import AccordionActions from './components/AccordionActions'
import ScreenshotGallery from './components/ScreenshotGallery'
import MomentsGallery from './components/MomentsGallery'
import { useMixpanel } from '../../hooks/useMixpanel'

export default function Exams() {
  const { getExams, getExamScreenshots, getExamsImageIa } = useApi()

  const [pagination, setPagination] = useState({ currentPage: 1, pageSize: 10 })

  const [openExam, setOpenExam] = useState(false)
  const [openAccordionExam, setOpenAccordionExam] = useState(true)
  const [openAccordionActions, setOpenAccordionActions] = useState(true)
  const [selectedExam, setSelectedExam] = useState<Exam | null>(null)
  const [selectedMedia, setSelectedMedia] = useState('video')
  const [screenshotDialogOpen, setScreenshotDialogOpen] = useState(false)
  const [momentsDialogOpen, setMomentsDialogOpen] = useState(false)
  const [listOfMedia, setListOfMedia] = useState<{ thumb: string; type: string; src: string }[]>([])
  const { trackEvent } = useMixpanel()

  const { data, isLoading } = useQuery(['exams', pagination.currentPage - 1], () =>
    getExams(pagination.currentPage - 1)
  )
  const { data: screenshots } = useQuery(['screenShots', selectedExam?.exam], () =>
    getExamScreenshots(selectedExam?.exam || null)
  )
  const { data: media } = useQuery(['mediaIa', selectedExam?.exam], () =>
    getExamsImageIa(selectedExam?.exam || null)
  )

  const listOfScreenshots = screenshots
    ? screenshots?.Screenshots.map((screenshot) => ({
        thumb: screenshot.url,
        type: 'image',
      }))
    : []

  const [selectedScreenShot, setSelectedScreenShot] = useState({
    index: 0,
    thumb: listOfScreenshots[0]?.thumb,
    type: 'image',
  })

  const [selectedMoment, setSelectedMoment] = useState({
    index: 0,
    thumb: listOfScreenshots[0]?.thumb,
    type: 'image',
    src: '',
  })


/*   useEffect(() => {
    trackEvent("WEB_PATIENT_LISTEXAMS", {
      examsAmount: data?.total_items
    })
  }, []) */

  useEffect(() => {
    if (!openExam) {
      setSelectedMedia('video')
      setOpenAccordionActions(true)
      setOpenAccordionExam(true)
    }
  }, [openExam])

  useEffect(() => {
    if (media) {
      const keyframes = media?.keyframes
        ? media?.keyframes.map((keyframe) => ({
            thumb: keyframe,
            type: 'image',
            src: '',
          }))
        : []

      const videos_ecg_anonymous = media?.videos_ecg_anonymous
        ? media?.videos_ecg_anonymous.map((video) => ({
            src: video,
            type: 'video',
            thumb: 'https://cdn.pixabay.com/photo/2017/11/10/05/34/play-2935460_640.png',
          }))
        : []

      const videos_3d_anonymous = media?.videos_3d_anonymous
        ? media?.videos_3d_anonymous.map((video) => ({
            src: video,
            type: 'video',
            thumb: 'https://cdn.pixabay.com/photo/2017/11/10/05/34/play-2935460_640.png',
          }))
        : []

      setListOfMedia([...keyframes, ...videos_ecg_anonymous, ...videos_3d_anonymous])
    }
  }, [media])

  function mediaToRender() {
    if (!selectedExam) return null

    if (selectedMedia === 'video') {
      return (
        <Clappr
          fallBackAudioUrl={''}
          id={selectedExam.exam}
          introUrl={''}
          m3u8Url={`${process.env.REACT_APP_API_URL}/exam/${selectedExam.exam}/vod.m3u8`}
          mp4Url={''}
          organization={selectedExam.organization}
          examId={selectedExam.exam}
          autoplay={true}
          blurName={true}
          muted={false}
          key={selectedExam.id}
        />
      )
    }

    if (selectedMedia === 'images') {
      if (listOfScreenshots){
        trackEvent("WEB_PATIENT_LISTSCREENSHOT", {
          screenshotsAmount: listOfScreenshots.length,
          isVlabStaff: selectedExam.organization.toLocaleLowerCase() === 'vlab'
        })
      
        if(listOfScreenshots.length > 0) {

          return (
            <DoubleImageCarousel
              //@ts-ignore
              thumbs={listOfScreenshots}
              key={selectedExam.id}
              onSelected={(value) => {
                setSelectedScreenShot(value)
                setScreenshotDialogOpen(true)
              }}
              //@ts-ignore
              selected={selectedScreenShot}
            />
          )
        }

        return <p style={{ textAlign: 'center' }}>Nenhuma imagem está associada a este exame.</p>
      }
    }

    if (selectedMedia === 'moment') {
      trackEvent("WEB_PATIENT_BESTMOMENTS", {
        screenshotsAmount: listOfMedia.length,
        isVlabStaff: selectedExam.organization.toLocaleLowerCase() === 'vlab'
      })

      if (listOfMedia.length !== 0) {

        return (
          <DoubleImageCarousel
            //@ts-ignore
            thumbs={listOfMedia}
            key={selectedExam.id}
            onSelected={(value) => {
              //@ts-ignore
              setSelectedMoment(value)
              setMomentsDialogOpen(true)
            }}
            //@ts-ignore
            selected={selectedMoment}
          />
        )
      }

      return (
        <p style={{ textAlign: 'center' }}>Nenhum melhor momento está associada a este exame.</p>
      )
    }
  }

  const showOptions = () => listOfMedia.length !== 0 || listOfScreenshots.length !== 0

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {isLoading ? (
        <LoadingContainer>
          <CircularProgress style={{ width: '50px', height: '50px' }} />
        </LoadingContainer>
      ) : (
        <>
          {openExam && <Opacity onClick={() => setOpenExam(false)} />}

          <FlexContainer gap={'16px'} wrap="wrap" width="100%" justifyContent="center">
            {data?.items.map((exam) => (
              <ExamCard
                key={exam.id}
                exam={exam}
                image={'images/babyPlay.png'}
                onClickCard={() => {
                  setOpenExam(true)
                  setSelectedExam(exam)
                }}
              />
            ))}
          </FlexContainer>

          <Sidebar
            variant={'secondary'}
            open={openExam}
            position="right"
            width={560}
            style={
              !openExam
                ? { width: '0px' }
                : { overflowY: 'scroll', maxWidth: '560px', zIndex: 6, backgroundColor: '#fff' }
            }
            fullScreenOnMobile
          >
            {openExam && (
              <Content>
                <Header>
                  <CloseIconWrapper onClick={() => setOpenExam(false)}>
                    <CloseIcon width={12} height={12} />
                  </CloseIconWrapper>

                  <Typography color="primary" type="subheadingS">
                    Exame
                  </Typography>
                </Header>

                <Wrapper>
                  <FlexContainer
                    margin="0 0 24px"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography color="black" type="bodyL">
                      Mídia
                    </Typography>

                    {showOptions() && (
                      <Select
                        label=""
                        value={selectedMedia}
                        style={{ width: '230px' }}
                        name=""
                        options={[
                          { label: 'Vídeo', value: 'video' },
                          { label: 'Imagens', value: 'images' },
                          { label: 'Melhores momentos', value: 'moment' },
                        ]}
                        onClick={(e) => setSelectedMedia(e.target.value)}
                      />
                    )}
                  </FlexContainer>

                  {mediaToRender()}

                  {selectedExam && (
                    <>
                      <AccordionExam
                        open={openAccordionExam}
                        setOpen={setOpenAccordionExam}
                        selectedExam={selectedExam}
                      />
                      <AccordionActions
                        open={openAccordionActions}
                        setOpen={setOpenAccordionActions}
                        selectedExam={selectedExam}
                      />
                    </>
                  )}
                </Wrapper>
              </Content>
            )}
          </Sidebar>

          <ScreenshotGallery
            listOfScreenshots={listOfScreenshots}
            open={screenshotDialogOpen}
            setOpen={setScreenshotDialogOpen}
            selectedScreenShot={selectedScreenShot}
            setSelectedScreenShot={setSelectedScreenShot}
          />

          <MomentsGallery
            listOfMedia={listOfMedia}
            open={momentsDialogOpen}
            setOpen={setMomentsDialogOpen}
            selectedMoment={selectedMoment}
            setSelectedMoment={setSelectedMoment}
          />

          <Pagination
            total={data?.total_items || 0}
            disabled={false}
            currentPage={data?.current_page || 0}
            pageSize={data?.page_size || 0}
            totalPages={data?.total_pages || 0}
            onPageChange={(page) =>
              setPagination((previous) => ({ ...previous, currentPage: page }))
            }
          />
        </>
      )}
    </div>
  )
}
