import { Dialog, DownloadIcon, PDFViewer, Typography } from 'vlab-frontend-components'

import AccordionResult from '../AccordionResult'
import { ResultFiles, Results } from '../../../../types/Results'
import { useEffect, useState } from 'react'
import {
  AccordionHeader,
  AccordionWrapper,
  EmptyContainer,
  ImageWrapper,
  ResultContainer,
  Wrapper,
} from './styles'
import VideoRender from '../VideoRender'
import FlexContainer from 'vlab-frontend-components/dist/cjs/components/FlexContainer/FlexContainer'
import { useApi } from '../../../../hooks/useAPi'
import { StatusDownloadDialog } from '../../../../components/StatusDownloadDialog'

interface ModalProps {
  open: boolean
  setOpen: (value: boolean) => void
  results: Results[]
}

interface ImageViewerProps {
  src: string
  alt?: string
  width?: string
  height?: string
}

const ImageViewer = ({ src, alt = '', width = '100%', height = 'auto' }: ImageViewerProps) => (
  <ImageWrapper height={height} width={width}>
    <img src={src} alt={alt} />
  </ImageWrapper>
)

export default function Modal({ open, setOpen, results }: ModalProps) {
  const [selectedFile, setSelectedFile] = useState<Partial<ResultFiles> | null>(null)
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [selectedsResults, setSelectedsResults] = useState<any[]>([])
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false)
  const [downloadStatus, setDownloadStatus] = useState('')
  const [percentageVideoDownload, setPercentageVideoDownload] = useState(0)
  const [downloadError, setDownloadError] = useState(false)

  const { getDownloadExam } = useApi()

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const downloadFiles = async () => {
    for (let i = 0; i < selectedsResults.length; i++) {
      const isPdf = selectedsResults[i].file_type === 1
      if (isPdf) {
        const response = await fetch(selectedsResults[i].url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/pdf',
          },
        })

        if (!response.ok) {
          throw new Error('Erro ao baixar o arquivo')
        }

        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob)
        downloadFilesHelper(url, selectedsResults[i].filename)
      } else if (selectedsResults[i].file_type === 3) {
        await downloadCompleteExamMP4(selectedsResults[i].url, selectedsResults[i].cicle)
      } else {
        downloadFilesHelper(selectedsResults[i].url, selectedsResults[i].filename)
      }
    }
  }

  const downloadFilesHelper = async (url: string, filename: string) => {
    const link = document.createElement('a')
    link.href = url
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const downloadCompleteExamMP4 = async (examId: string, cicle: string): Promise<void> => {
    setOpenDownloadDialog(true)
    try {
      const updatePercentage = (status: string) => {
        let percentage = 0

        switch (status) {
          case 'initialized':
            setDownloadStatus('Iniciando processamento do exame...')
            percentage = 10
            break
          case 'enqueued':
            setDownloadStatus('Aguardando processamento do exame...')
            percentage = 40
            break
          case 'generating':
            setDownloadStatus('Processando exame para download...')
            percentage = 66
            break
          case 'ready':
            setDownloadStatus('Exame pronto para download.')
            percentage = 100
            break
          default:
            setDownloadStatus(
              'Exame temporariamente indisponível para download. Tente novamente mais tarde.'
            )
            throw Error()
        }
        setPercentageVideoDownload(percentage)
      }

      const downloadChunk = async (): Promise<string> => {
        await new Promise((resolve) => setTimeout(resolve, 1500))
        const response = await getDownloadExam(examId)
        const videoUrl = response.url || ''
        if (response.status === 'ready' && videoUrl !== '') {
          await downloadFilesHelper(videoUrl, examId)
        }
        return response.status
      }
      while (true) {
        const status = await downloadChunk()
        updatePercentage(status)

        if (status === 'ready' || status === 'cancelled') {
          await new Promise((resolve) => setTimeout(resolve, 1500))
          break
        }
      }
    } catch (error) {
      setDownloadError(true)
    }
  }

  return (
    <Dialog
      isOpen={open}
      onClose={() => setOpen(false)}
      title="Resultados"
      contentStyle={{
        width: '100%',
        height: '100%',
        justifyContent: 'flex-start',
        maxWidth: '90vw',
        maxHeight: '90vh',
      }}
    >
      <StatusDownloadDialog
        open={openDownloadDialog}
        setOpen={(value) => {
          setOpenDownloadDialog(value)
        }}
        percentage={percentageVideoDownload}
        status={downloadStatus}
        error={downloadError}
      />
      <Wrapper>
        <ResultContainer>
          <AccordionWrapper>
            <AccordionHeader>
              <FlexContainer alignItems="center" gap="8px">
                <input
                  type="checkbox"
                  disabled={selectedsResults.length === 0}
                  checked={selectedsResults.length > 0}
                  onClick={() => {
                    if (selectedsResults.length > 0) {
                      setSelectedsResults([])
                    }
                  }}
                />
                {selectedsResults.length > 0 && (
                  <Typography color="black" type="poppinsBodyS" style={{ fontWeight: 300 }}>
                    {selectedsResults.length} selecionados
                  </Typography>
                )}
              </FlexContainer>

              <div
                style={{ cursor: selectedsResults.length > 0 ? 'pointer' : 'not-allowed' }}
                onClick={() => {
                  if (selectedsResults.length > 0) {
                    downloadFiles()
                  }
                }}
              >
                <DownloadIcon />
              </div>
            </AccordionHeader>
            {results.map((result) => (
              <>
                <AccordionResult
                  result={result}
                  key={result.id}
                  setSelectedFile={setSelectedFile}
                  setSelectedsResults={setSelectedsResults}
                  selectedsResults={selectedsResults}
                />
              </>
            ))}
          </AccordionWrapper>
          <div style={{ width: !selectedFile ? '60%' : 'fit-content' }}>
            {!selectedFile && (
              <EmptyContainer>
                <Typography color="black" type="poppinsBodyL" style={{ fontWeight: 300 }}>
                  Selecione um arquivo para visualizar
                </Typography>
              </EmptyContainer>
            )}

            {selectedFile && selectedFile.file_type === 1 && (
              <PDFViewer
                file={selectedFile.url || ''}
                height={600}
                width={windowSize <= 870 ? 300 : 700}
              />
            )}
            {selectedFile && selectedFile.file_type === 2 && (
              <ImageViewer src={selectedFile.url || ''} height="100%" width="100%" />
            )}
            {selectedFile && selectedFile.file_type === 3 && (
              <div
                style={{
                  width: windowSize <= 870 ? 300 : 600,
                  height: '100%',
                }}
              >
                <VideoRender
                  openReport={true}
                  selectedReport={{
                    exam: selectedFile.url || '',
                  }}
                />
              </div>
            )}
          </div>
        </ResultContainer>
      </Wrapper>
    </Dialog>
  )
}
