import styled from "styled-components"


export const SeeReportContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease;

`

export const Opacity = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
  top: 0;
  left: 0;
`

export const Content = styled.div`
  
`

export const Header = styled.div`
  height: 69px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 0px 24px;
`

export const CloseIconWrapper = styled.div`
  cursor: pointer;
  margin-right: 16px;
`

export const Wrapper = styled.div`
  padding:  24px;
`

export const DrawerContent = styled.div`
    width: 100%;
    margin-bottom: 24px;
`


export const CellTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`