import { CircularProgress, CloseIcon, Select, Sidebar, Typography } from 'vlab-frontend-components'
import { useEffect, useState } from 'react'
import FlexContainer from '../../components/Flex'
import { Opacity, Content, CloseIconWrapper, Wrapper, Header, DrawerContent } from './style'
import { useApi } from '../../hooks/useAPi'
import { useQuery } from 'react-query'
import { MedicalReports } from '../../types/medicalReports'
import Pagination from '../../components/Pagination'
import { pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import ReportCard from './components/Card'
import { LoadingContainer } from '../../components/LoadingContainer'
import GreetingByTime from '../../components/GreetingByTime'
import { useAtom } from 'jotai'
import { clientConfigAtom } from '../Login'
import { getS3BackgroundUrl, getS3LogoUrl } from '../../utils/s3'
import PdfGalleryWithViewer from '../../components/PdfGalleryWithViewer'
import { useMixpanel } from '../../hooks/useMixpanel'
import Modal from './components/Modal'
import { Results } from '../../types/Results'

export interface MedicalReportsData extends MedicalReports {
  report_url_pdf: string
}

export default function Exams() {
  const [openReport, setOpenReport] = useState(false)
  const [openAccordionReport, setOpenAccordionReport] = useState(true)
  const [openAccordionActions, setOpenAccordionActions] = useState(true)
  const [selectedReport, setSelectedReport] = useState<MedicalReports | null>(null)
  const [pagination, setPagination] = useState({ currentPage: 1, pageSize: 10 })
  const [selectedMedia, setSelectedMedia] = useState('report')
  const [clientConfig, setClientConfig] = useAtom(clientConfigAtom)
  const [results, setResults] = useState<Results[]>([])

  const { getMedicalReports, getResult } = useApi()

  const { data, isLoading } = useQuery(['medicalReports', pagination.currentPage - 1], () =>
    getMedicalReports(pagination.currentPage - 1), {
    refetchOnWindowFocus: false,
  }
  )
  const { trackEvent } = useMixpanel()

  async function getResults(reportId: string) {
    const response = await getResult(reportId)

    if (response) {
      return response
    }
  }

  async function handleCardClick(report: MedicalReports) {
    const resultsData = await getResults(report.id)
    if (resultsData && resultsData.length > 0) {
      setResults(resultsData)
    }

    setOpenReport(true)
  }

  async function getClientConfig() {
    await getS3LogoUrl().then((url) => {
      setClientConfig((prev) => ({
        ...prev,
        patient_portal_logo: url,
      }))
    })
    await getS3BackgroundUrl().then((url) => {
      setClientConfig((prev) => ({
        ...prev,
        patient_portal_background: url,
      }))
    })
  }

  useEffect(() => {
    trackEvent('WEB_PATIENT_LISTRESULTS', {
      resultsAmount: data?.total_items.toString(),
    })
  }, [])

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
    getClientConfig()
  }, [])

  useEffect(() => {
    if (!openReport) {
      setSelectedMedia('report')
      setOpenAccordionActions(true)
      setOpenAccordionReport(true)
    }
  }, [openReport])

  return (
    <div>
      {openReport && (
        <Opacity
          onClick={() => {
            setOpenReport(false)
          }}
        />
      )}

      {isLoading ? (
        <LoadingContainer>
          <CircularProgress style={{ width: '50px', height: '50px' }} />
        </LoadingContainer>
      ) : (
        <>
          <GreetingByTime />

          <FlexContainer gap={'16px'} wrap="wrap" width="100%" justifyContent="center">
            {data?.items.map((report) => (
              <ReportCard
                key={report.id}
                report={report}
                onClickCard={async () => await handleCardClick(report)}
                clientConfig={clientConfig}
              />
            ))}
          </FlexContainer>

          {/* <Sidebar
            variant={'secondary'}
            open={openReport}
            position="right"
            width={560}
            fullScreenOnMobile
            style={
              !openReport
                ? { width: '0px' }
                : { overflowY: 'scroll', maxWidth: '560px', zIndex: 6, backgroundColor: '#ffffff' }
            }
          >
            <Content>
              <Header>
                <CloseIconWrapper
                  onClick={() => {
                    setOpenReport(false)
                  }}
                >
                  <CloseIcon width={12} height={12} />
                </CloseIconWrapper>

                <Typography color="primary" type="subheadingS">
                  {selectedReport?.identifier}
                </Typography>
              </Header>

              <Wrapper>
                <FlexContainer margin="0 0 24px" alignItems="center" justifyContent="space-between">
                  <Typography color="black" type="bodyL">
                    {selectedMedia === 'report' ? 'Laudo' : 'Ultrassom'}
                  </Typography>

                  {selectedReport?.exam && (
                    <Select
                      label=""
                      value={selectedMedia}
                      style={{ width: '230px' }}
                      name=""
                      options={[
                        { label: 'Laudo', value: 'report' },
                        { label: 'Ultrassom', value: 'ultrasound' },
                      ]}
                      onClick={(e) => setSelectedMedia(e.target.value)}
                    />
                  )}
                </FlexContainer>

                <DrawerContent>
                  {selectedMedia === 'report' &&
                  selectedReport?.filesUrls &&
                  selectedReport.filesUrls.length > 0 ? (
                    <PdfGalleryWithViewer
                      report={selectedReport}
                      files={selectedReport?.filesUrls}
                      widthPdfViewer={500}
                    />
                  ) : (
                    <VideoRender selectedReport={selectedReport} openReport={openReport} />
                  )}
                </DrawerContent>

                {selectedReport && (
                  <>
                    <AccordionReport
                      report={selectedReport}
                      open={openAccordionReport}
                      setOpen={setOpenAccordionReport}
                    />
                  </>
                )}
              </Wrapper>
            </Content>
          </Sidebar> */}
          {openReport && <Modal open={openReport} setOpen={setOpenReport} results={results} />}

          <Pagination
            total={data?.total_items || 0}
            disabled={false}
            currentPage={data?.current_page || 0}
            pageSize={data?.page_size || 0}
            totalPages={data?.total_pages || 0}
            onPageChange={(page) =>
              setPagination((previous) => ({ ...previous, currentPage: page }))
            }
          />
        </>
      )}
    </div>
  )
}
